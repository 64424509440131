import Vue from "vue";
import api from "@/api";

const mapping = {
  request_table_custom_cols: "requestTableCustomCols",
};

export default {
  state: {
    requestTableCustomCols: [],
    fetched: false,
    fetching: false,
  },

  mutations: {
    SET_REQUEST_TABLE_CUSTOM_COLS: (state, value) => {
      state.requestTableCustomCols = value;
    },

    USER_PREFERENCES_START_FETCHING: (state) => {
      state.fetched = false;
      state.fetching = true;
    },

    USER_PREFERENCES_FETCH_FAILED: (state) => {
      state.fetched = false;
      state.fetching = false;
    },

    USER_PREFERENCES_FETCHED: (state, preferences) => {
      if (Array.isArray(preferences)) {
        preferences.forEach((preference) => {
          if (preference && preference.key && mapping[preference.key]) {
            const stateProp = mapping[preference.key];
            Vue.set(state, stateProp, JSON.parse(preference.value));
          }
        });
      }

      state.fetched = true;
    },
  },

  actions: {
    async fetchUserPreferences({ commit }, userId) {
      commit("USER_PREFERENCES_START_FETCHING");

      try {
        const preferences = await api.userPreferences.query({ userId });
        commit("USER_PREFERENCES_FETCHED", preferences);
      } catch {
        commit("USER_PREFERENCES_FETCH_FAILED");
      }
    },

    async saveRequestColsPreferences({ commit }, { value }) {
      commit("SET_REQUEST_TABLE_CUSTOM_COLS", value);

      try {
        await api.userPreferences.saveConfig({
          key: "request_table_custom_cols",
          value: JSON.stringify(value),
        });
      } catch {
        // TODO: log error
      }
    },
  },
};
