import moment from "moment";

export const getQueryFromFilters = (filters = {}, order = {}, limit, page) => {
  const query = {
    limit,
    page,
    orderBy: order.field,
    orderDirection: order.direction,
  };

  const dateFormat = "YYYY-MM-DD";

  const acceptableParams = [
    "status",
    "text",
    "field",
    "country",
    "assignees",
    "groups",
    "location",
    "category",
    "typeSponsorship",
    "dateEvent",
    "budgetYear",
  ];

  for (const param of acceptableParams) {
    if (filters && filters[param]) {
      query[param] = filters[param];
    }
  }

  if (
    filters.states &&
    Array.isArray(filters.states) &&
    filters.states.length
  ) {
    query.states = filters.states.map((o) => o.value);
  }

  if (filters.period && filters.period === "year") {
    query.period_start = moment().startOf("year").format(dateFormat);
  }

  if (filters.period && filters.period === "6_mo") {
    query.period_start = moment().subtract(6, "months").format(dateFormat);
  }

  if (filters.period && filters.period === "3_mo") {
    query.period_start = moment().subtract(3, "months").format(dateFormat);
  }

  if (filters.period && filters.period === "last_month") {
    query.period_start = moment().subtract(1, "months").format(dateFormat);
  }

  if (
    filters.period &&
    filters.period === "custom" &&
    filters.period_start &&
    filters.period_end
  ) {
    query.period_start = moment(filters.period_start).format(dateFormat);
    query.period_end = moment(filters.period_end).format(dateFormat);
  }

  if (filters.amount) {
    query.amountMin = filters.amount.min;
    query.amountMax = filters.amount.max;
  }

  if (filters.audience) {
    query.audienceMin = filters.audience.min;
    query.audienceMax = filters.audience.max;
  }

  if (filters.typeOrganization) {
    const uniqueValues = [];

    filters.typeOrganization.forEach((item) => {
      const values = item.split(",");

      values.forEach((value) => {
        if (!uniqueValues.includes(value)) {
          uniqueValues.push(value);
        }
      });
    });

    query.typeOrganization = uniqueValues;
  }

  return query;
};
