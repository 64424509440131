<template>
  <div
    :style="{ color: adminTextColor, backgroundColor: adminBackgroundColor }"
    class="app-header d-print-none"
  >
    <Placeholder v-if="!isLoaded" />
    <div v-else class="container-fluid d-flex justify-content-between p-0">
      <div class="brand">
        <router-link
          v-if="hasPermission(permissionEnum.WebsiteManage)"
          :to="{ name: 'websites' }"
        >
          <AdminBrand
            :textColor="adminTextColor"
            defaultName="Elevent Super Admin"
          />
        </router-link>
        <router-link v-else :to="{ name: 'dashboard' }">
          <AdminBrand
            v-if="hasLicenseFeature(featureEnum.STYLES)"
            :textColor="adminTextColor"
          />
        </router-link>
      </div>
      <ul class="user-menu">
        <div
          v-if="
            $store.state.auth.user.website !== 'null' &&
            hasLicenseFeature(featureEnum.PAGES)
          "
          class="mx-2"
        >
          <a
            :href="publicWebsiteUrl"
            :style="{ color: adminTextColor }"
            target="_blank"
            >{{ $t("admin.auth.publicWebsite") }}&nbsp;
            <fa-icon
              :icon="['fal', 'external-link-alt']"
              class="button-icon m-0"
            ></fa-icon>
          </a>
        </div>

        <li class="pull-right mx-2">
          <a
            :style="{ color: adminTextColor }"
            aria-expanded="false"
            class="dropdown-toggle"
            data-toggle="dropdown"
            href="#"
          >
            <fa-icon :icon="['fal', 'user']" class="user-icon"></fa-icon>
            {{ currentUser.name }} <span class="caret"></span>
          </a>
          <ul class="dropdown-menu" role="menu">
            <li>
              <router-link :to="{ name: 'password.change' }">
                <fa-icon :icon="['fal', 'lock']" class="mr-1"></fa-icon>
                {{ $t("admin.auth.changePassword") }}
              </router-link>
            </li>
            <li>
              <a href="" @click="logout">
                <fa-icon :icon="['fal', 'power-off']" class="mr-1"></fa-icon>
                {{ $t("admin.auth.logout") }}</a
              >
            </li>
          </ul>
        </li>
        <div v-if="!hasLicense(licenseEnum.PUBLIX)" class="lang-switch px-2">
          <language-switch></language-switch>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import permissions from "@/mixins/permissions";
import userData from "@/mixins/user-data";
import LanguageSwitch from "@/components/layout/header/LanguageSwitch";
import AdminBrand from "@/components/layout/header/AdminBrand";
import Placeholder from "@/components/common/Placeholder";
import config from "@/config";
import licenses from "@/mixins/licenses";

export default {
  mixins: [userData, licenses, permissions],
  components: {
    LanguageSwitch,
    AdminBrand,
    Placeholder,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  props: {
    lightHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    backRoute: {
      type: Object,
      required: false,
    },
  },
  computed: {
    adminBackgroundColor() {
      return this.$store.state.styles.styles.admin_header_color
        ? this.$store.state.styles.styles.admin_header_color
        : config.defaults.adminHeaderColor;
    },
    adminTextColor() {
      return this.$store.state.styles.styles.admin_header_text_color
        ? this.$store.state.styles.styles.admin_header_text_color
        : config.defaults.adminTextHeaderColor;
    },
    publicWebsiteUrl() {
      const user = this.$store.state.auth.user;
      if (user.website === null || user.website === "undefined") {
        return "";
      }
      const website = JSON.parse(user.website);

      if (website.custom_hostname) {
        return `https://${website.custom_hostname}`;
      }

      return `https://${website.subdomain}.elevent.co`;
    },
  },
  methods: {
    async fetchData() {
      this.isLoaded = false;

      if (
        this.currentUser.website_id != null &&
        !this.$store.state.styles.stylesFetched &&
        this.hasLicenseFeature(this.featureEnum.STYLES)
      ) {
        await this.$store.dispatch("fetchStyles", this.currentUser.website_id);
      }

      this.isLoaded = true;
    },
    onCancelClick() {
      if (this.backRoute) {
        this.$router.push(this.backRoute);
      }
    },
    logout() {
      this.$store.dispatch("logout").catch((error) => {
        this.error = error;
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
$header-height: $logo-height;

.app-header {
  display: block;

  height: $header-height;
  color: $main-color;
  background: $secondary-color;
  z-index: 900;

  .brand {
    line-height: $header-height;
    height: $header-height;
    font-weight: 500;
    font-size: 16px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .menu {
    .lang {
      cursor: pointer;
    }
  }

  .cancel-button {
    cursor: pointer;
  }

  .lang-switch {
    line-height: $header-height;
    border-left: 1px solid $wt;

    &:hover {
      text-decoration: none;
    }
  }

  .user-icon {
    margin-right: 5px;
    font-size: 15px;
  }
}
</style>
