import api from "@/api";

export default {
  state: {
    currentLicense: {
      value: {},
      fetched: false,
      fetching: false,
    },
    licenses: {
      values: {},
      fetched: false,
      fetching: false,
    },
  },

  mutations: {
    CURRENT_LICENSE_FETCHING: (state) => {
      state.currentLicense.fetching = true;
    },

    CURRENT_LICENSE_FETCHED: (state, license) => {
      state.currentLicense.value = license;
      Object.freeze(state.currentLicense.value);

      state.currentLicense.fetching = false;
      state.currentLicense.fetched = true;
    },

    CURRENT_LICENSE_STOP_FETCHING: (state) => {
      state.currentLicense.fetching = false;
    },

    LICENSES_START_FETCHING: (state) => {
      state.licenses.fetching = true;
    },

    LICENSES_FETCHED: (state, licenses) => {
      const licensesObj = {};
      licenses.forEach((license) => {
        licensesObj[license.name.toUpperCase()] = license;
      });
      state.licenses.values = licensesObj;
      Object.freeze(state.licenses.values);

      state.licenses.fetching = false;
      state.licenses.fetched = true;
    },

    LICENSES_STOP_FETCHING: (state) => {
      state.licenses.fetching = false;
    },
  },

  actions: {
    async fetchCurrentLicense({ commit }, licenseId) {
      commit("CURRENT_LICENSE_FETCHING");
      try {
        const licenses = await api.getWebsiteLicense(licenseId);
        commit("CURRENT_LICENSE_FETCHED", licenses);
      } catch {
        commit("CURRENT_LICENSE_STOP_FETCHING");
      }
    },
    async fetchLicenses({ commit }) {
      commit("LICENSES_START_FETCHING");
      try {
        const licenses = await api.getLicenses();
        commit("LICENSES_FETCHED", licenses);
      } catch {
        commit("LICENSES_STOP_FETCHING");
      }
    },
  },
};
