import api from "@/api";
export default {
  state: {
    items: [],
    fetching: false,
    permissionsFetched: false,
  },

  mutations: {
    PERMISSIONS_START_FETCHING: (state) => {
      state.fetching = true;
    },
    PERMISSIONS_FETCHED: (state, permissions) => {
      state.fetching = false;
      state.permissionsFetched = true;
      state.items = permissions;
      Object.freeze(state.items);
      localStorage.setItem("permissions", permissions);
    },
  },

  actions: {
    async fetchPermissions({ commit }) {
      commit("PERMISSIONS_START_FETCHING");
      try {
        const permissions = await api.getPermissions();
        commit("PERMISSIONS_FETCHED", permissions);
      } catch {
        commit("PERMISSIONS_STOP_FETCHING");
      }
    },
  },
};
