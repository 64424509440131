import Vue from "vue";
import Vuex from "vuex";
import auth from "./store/auth";
import navigation from "./store/navigation";
import websites from "./store/superadmin/websites";
import users from "./store/superadmin/users";
import budgets from "./store/siteadmin/budgets";
import accounts from "./store/siteadmin/accounts";
import accountBudgets from "./store/siteadmin/accountBudgets";
import products from "./store/siteadmin/products";
import sponsorshipBudget from "./store/siteadmin/sponsorshipBudget";
import questions from "./store/siteadmin/questions";
import requests from "./store/siteadmin/requests";
import emails from "./store/siteadmin/emails";
import profile from "./store/siteadmin/profile";
import pages from "./store/siteadmin/pages";
import styles from "./store/siteadmin/styles";
import settings from "./store/siteadmin/settings";
import siteUsers from "./store/siteadmin/site-users";
import groups from "./store/siteadmin/groups";
import preferences from "./store/siteadmin/preferences";
import publicSite from "./store/publicSite";
import accessibility from "@/store/accessibility";
import licenses from "@/store/siteadmin/licenses";
import features from "@/store/siteadmin/features";
import permissions from "@/store/siteadmin/permissions";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accessibility,
    auth,
    budgets,
    accounts,
    accountBudgets,
    products,
    sponsorshipBudget,
    navigation,
    websites,
    users,
    questions,
    requests,
    emails,
    profile,
    pages,
    styles,
    settings,
    siteUsers,
    groups,
    preferences,
    publicSite,
    licenses,
    features,
    permissions,
  },
});
