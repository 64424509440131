export default {
  changePassword: "Change password",
  loginLinkInvalid:
    "Your link has expired, please contact your site administrator.",
  logout: "Logout",
  signIn: "Sign In",
  twoFactorLogin: "Two factor authentication",
  generateNewText1: "You didn't receive your code yet?",
  incorrectVerificationCode: "Verification code is invalid",
  generateNewText2: "Click here to generate a new one.",
  generateTokenConfirmation: "A new code has been sent to you successfully.",
  generateTokenError: "There was a problem sending the new code.",
  email: "Email",
  password: "Password",
  forgotPasswordLink: "Forgot password?",
  verificationCode: "Verification code",
  login: "Login",
  incorrectLoginMessage: "Incorrect login or password. Please try again.",
  forgotPasswordTitle: "Forgot password",
  enterEmailToRecoverPassword:
    "Please enter your email in the field below. A reset link will be sent to you.",
  emailNotFound: "Email address not found.",
  passwordThrottled:
    "You have recently requested a password reset, please check your email.",
  resetLinkSent: "A reset link has been sent to your email address.",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  currentPasswordIncorrect: "Current Password is incorrect",
  passwordHasBeenChanged: "Your password has been changed",
  chooseNewPassword: "Please choose a new password.",
  invalidPassword: "The password must contain at least 8 characters",
  invalidPasswordConfirmation:
    "The password confirmation and password must match.",
  invalidUser: "Please check your email address and try again.",
  invalidResetToken: "This reset link is not valid or has expired.",
  backToLogin: "Back to Login",
  publicWebsite: "Public website",
  forceChangePasswordModalBody: "Your password has expired.",
  forceChangePasswordModalBody2:
    "Your new password must contain at least one capital letter, one number and one special character.\n",
};
