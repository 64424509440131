export default {
  title: "Dashboard",
  submissions: "Requests",
  pending: "Pending",
  accepted: "Accepted",
  rejected: "Rejected",
  evaluation: "Evaluation",
  reviewing: "In review",
  negotiation: "Negotiation",
};
