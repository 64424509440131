// Bootstrap-vue
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";

// Honeybadger

import HoneybadgerVue from "@honeybadger-io/vue";

const honeybadgerConfig = {
  apiKey: process.env.VUE_APP_HONEYBADGER_API_KEY,
  environment: process.env.VUE_APP_APP_ENV,
};

if (process.env.VUE_APP_APP_ENV !== "local") {
  Vue.use(HoneybadgerVue, honeybadgerConfig);

  if (localStorage.getItem("user_id")) {
    Vue.$honeybadger.setContext({
      user_id: localStorage.getItem("user_id"),
    });
  }
}

import VCalendar from "v-calendar";

Vue.use(VCalendar);

// Vue Modal

import VueModal from "@/vendor/vue-modal-box";
import jquery from "jquery";

//import "./styles/main.scss";

import ToggleButton from "vue-js-toggle-button";

import wysiwyg from "vue-wysiwyg";

import Meta from "vue-meta";

Vue.use(Meta);

Vue.use(ToggleButton);

Vue.use(BootstrapVue);

window.$ = jquery;
window.jQuery = jquery;

require("bootstrap/dist/js/bootstrap");

Vue.use(VueModal);

Vue.use(wysiwyg, {
  hideModules: {
    link: true,
    code: true,
    image: true,
    table: true,
    separator: true,
    removeFormat: true,
  },
});

// Font Awesome

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  far,
  fas,
  fal,
  faFacebook,
  faInstagram,
  faXTwitter,
  faTiktok,
  faYoutube,
);
Vue.component("fa-icon", FontAwesomeIcon);

// Vue select
import vSelect from "vue-select";

import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

// Vue Noty

import VueNoty from "vuejs-noty";

Vue.use(VueNoty, {
  timeout: 4000,
  progressBar: true,
  theme: "mint",
  layout: "topCenter",
});

// Vue File Upload

import VueUploadComponent from "vue-upload-component";

Vue.component("file-upload", VueUploadComponent);

// Luxon & Datetime

import { Settings } from "luxon";

Settings.defaultLocale = "en";

// Vue ScrollTo

import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

// Authentication

import store from "./store";
import config from "./config";

const token = localStorage.getItem(config.auth.tokenKeyName);

if (token) {
  // TODO: manage token expiration

  store.commit("CURRENT_USER_FETCHED", {
    id: localStorage.getItem("user_id"),
    name: localStorage.getItem("user_name"),
    role: localStorage.getItem("user_role"),
    website_id: localStorage.getItem("user_websiteId"),
    website: localStorage.getItem("user_website"),
    license: localStorage.getItem("user_license_id"),
  });

  store.commit("USER_AUTHENTICATED");
}
