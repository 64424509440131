import _ from "lodash";
import api from "@/api";
import { getQueryFromFilters } from "@/utils/requests";

const initialFilters = {
  status: "",
  text: "",
  field: "",
  period: "",
  period_start: "",
  period_end: "",
  country: "",
  states: [],
  assignees: [],
  groups: [],
  location: "",
  amount: {},
  audience: {},
  typeSponsorship: "",
  typeOrganization: "",
  dateEvent: "",
  category: "",
};

const setSponsorshipBudget = (state, submissionId, budget) => {
  const object = _.find(state.requests, { id: submissionId });

  const newObject = {
    ...object,
    sponsorship_budget: budget,
  };

  if (object) {
    const index = state.requests.indexOf(object);
    state.requests.splice(index, 1, newObject);
  }
};

export default {
  state: {
    siteId: null,
    requests: [],
    request: [],
    requestsFetched: false,
    requestsLoading: false,
    requestSaved: false,
    filters: {
      showMore: false,
      ...initialFilters,
    },
    pagination: {
      limit: 20,
      page: 1,
      pageCount: null,
      resultCount: null,
    },
    ids: [],
    orderBy: {
      field: "reference",
      direction: "desc",
    },
    budgetSummary: {
      isLoading: false,
      totalAmount: 0,
      totalProducts: 0,
      totalFinancial: 0,
    },
  },

  mutations: {
    REQUESTS_START_FETCHING: (state) => {
      state.requestsLoading = true;
    },

    REQUESTS_STOP_FETCHING: (state) => {
      state.requestsLoading = false;
    },

    REQUESTS_FETCHED: (state, { requests, count, pages, ids }) => {
      state.pagination = {
        ...state.pagination,
        pageCount: pages,
        resultCount: count,
      };
      state.ids = ids;
      state.requests = requests;
      state.requestsFetched = true;
      state.requestsLoading = false;
    },

    STORE_REQUESTS: (state, { requests, ids }) => {
      state.ids = ids;
      state.requests = requests;
    },

    REQUEST_FETCHED: (state, request) => {
      state.request = request;
    },

    REQUEST_DELETED: (state, payload) => {
      const id = payload.submissionId;
      const object = _.find(state.requests, { id });

      if (object) {
        const index = state.requests.indexOf(object);
        state.requests.splice(index, 1);
      }
    },

    REQUEST_STATUS_CHANGED: (state, request) => {
      const object = _.find(state.requests, { id: request.id });
      const newObject = {
        ...object,
        status: request.status,
      };

      if (object) {
        const index = state.requests.indexOf(object);
        state.requests.splice(index, 1, newObject);

        state.request = {
          ...state.request,
          submission: {
            ...state.request.submission,
            status: request.status,
          },
        };
      }
    },

    TOGGLE_REQUEST_FILTERS: (state, status) => {
      state.filters = {
        ...state.filters,
        showMore: status,
      };
    },

    REQUESTS_RESET_FILTERS: (state, status = false) => {
      state.filters = {
        ...initialFilters,
        showMore: status,
      };

      state.pagination = {
        ...state.pagination,
        page: 1,
      };
    },

    REQUESTS_SET_ORDER: (state, { field, direction }) => {
      state.orderBy = {
        field,
        direction,
      };
    },

    SET_REQUESTS_PAGE: (state, page) => {
      state.pagination.page = page;
    },

    SPONSORSHIP_BUDGET_UPDATED: (state, budget) => {
      if (budget.submission_id) {
        setSponsorshipBudget(state, budget.submission_id, budget);
      }
    },

    REQUEST_ASSIGNEES_CHANGED: (state, request) => {
      const object = _.find(state.requests, { id: request.id });
      const newObject = {
        ...object,
        assignee_ids: request.assignees.map((g) => g.id),
      };

      if (object) {
        const index = state.requests.indexOf(object);
        state.requests.splice(index, 1, newObject);
      }
    },

    REQUEST_GROUP_CHANGED: (state, request) => {
      const object = _.find(state.requests, { id: request.id });
      const newObject = {
        ...object,
        group_ids: request.groups.map((g) => g.id),
      };

      if (object) {
        const index = state.requests.indexOf(object);
        state.requests.splice(index, 1, newObject);
      }
    },

    FETCH_ATTRIBUTED_BUDGET_SUMMARY: (state) => {
      state.budgetSummary.isLoading = true;
    },

    ATTRIBUTED_BUDGET_SUMMARY_FETCHED: (
      state,
      { totalAmount, totalProducts, totalFinancial },
    ) => {
      state.budgetSummary = {
        isLoading: false,
        totalAmount,
        totalProducts,
        totalFinancial,
      };
    },

    SET_REQUESTS_SITE: (state, id) => {
      state.siteId = id;
    },

    SET_REQUEST_PROPERTY: (state, property) => {
      state.request = {
        ...state.request,
        submission: {
          ...state.request.submission,
          property,
        },
      };
    },

    SET_REQUEST_SHARED_PROPERTY: (state, sharedProperty) => {
      state.request = {
        ...state.request,
        submission: {
          ...state.request.submission,
          property: {
            ...state.request.submission.property,
            shared_property: sharedProperty,
          },
        },
      };
    },

    SET_REQUEST_PROPERTY_REPORT: (state, propertyReport) => {
      state.request.submission.propertyReport = propertyReport;
    },

    RESET_STATES(state, newStates) {
      state.filters.states = newStates;
    },
  },

  actions: {
    async downloadCsv({ state }, { websiteId }) {
      const params = getQueryFromFilters(
        state.filters,
        state.orderBy,
        state.pagination.limit,
        state.pagination.page,
      );
      return api.downloadCsv(websiteId, params);
    },
    async downloadPdf({ state }, { websiteId, locale, cols }) {
      const params = getQueryFromFilters(
        state.filters,
        state.orderBy,
        state.pagination.limit,
        state.pagination.page,
      );

      return api.downloadPdf(websiteId, locale, {
        ...params,
        cols: cols,
      });
    },
    async fetchRequests({ commit, state, dispatch }, websiteId) {
      commit("REQUESTS_START_FETCHING");
      commit("SET_REQUESTS_SITE", websiteId);

      try {
        const params = getQueryFromFilters(
          state.filters,
          state.orderBy,
          state.pagination.limit,
          state.pagination.page,
        );

        dispatch("fetchBudgetSummary", { websiteId, params });
        const { data, count, pages, ids } = await api.searchRequests(
          websiteId,
          params,
        );

        commit("REQUESTS_FETCHED", {
          requests: data,
          count,
          pages,
          ids,
        });
      } catch {
        commit("REQUESTS_STOP_FETCHING");
      }
    },

    async storeRequests({ commit }, requests) {
      commit("STORE_REQUESTS", {
        requests,
        ids: requests.map((req) => req.id),
      });
    },

    async reloadRequests({ state, commit, dispatch }) {
      if (state.siteId) {
        commit("REQUESTS_RESET_FILTERS");
        await dispatch("fetchRequests", state.siteId);
      }
    },

    async reorderRequests(
      { commit, dispatch },
      { websiteId, orderBy, orderDirection },
    ) {
      commit("REQUESTS_SET_ORDER", {
        field: orderBy,
        direction: orderDirection,
      });

      await dispatch("fetchRequests", websiteId);
    },

    async fetchBudgetSummary({ commit }, { websiteId, params }) {
      commit("FETCH_ATTRIBUTED_BUDGET_SUMMARY");

      const response = await api.getRequestsBudgetSumamry(websiteId, params);

      commit("ATTRIBUTED_BUDGET_SUMMARY_FETCHED", {
        totalAmount: response.total_attributed,
        totalFinancial: response.total_financial_contribution,
        totalProducts: response.total_product_contribution,
      });
    },

    async fetchRequest({ commit }, payload) {
      return api.getCurrentRequest(payload).then((request) => {
        commit("REQUEST_FETCHED", request);
      });
    },

    async deleteRequest({ commit }, payload) {
      await api.deleteRequest(payload);
      commit("REQUEST_DELETED", payload);
    },

    async updateRequestStatus({ commit }, payload) {
      const newObj = await api.updateStatus(payload);
      commit("REQUEST_STATUS_CHANGED", newObj);
    },

    async setProperty({ commit }, payload) {
      commit("SET_REQUEST_PROPERTY", payload);
    },

    async setSharedProperty({ commit }, payload) {
      commit("SET_REQUEST_SHARED_PROPERTY", payload);
    },

    async setRequestPropertyReport({ commit }, payload) {
      commit("SET_REQUEST_PROPERTY_REPORT", payload);
    },
  },
};
