export default {
  title: "Tableau de bord",
  submissions: "Demandes",
  pending: "En attente",
  accepted: "Acceptée",
  rejected: "Rejetée",
  evaluation: "Évaluation",
  reviewing: "En révision",
  negotiation: "Négociation",
};
