export default {
  computed: {
    isUserAuthenticated() {
      return this.$store.state.auth.authenticated;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    websiteId() {
      return this.currentUser.website_id;
    },
    userWebsite() {
      if (this.currentUser.website === undefined) {
        return null;
      }
      return JSON.parse(this.currentUser.website);
    },
    userLicenseId() {
      let website = this.userWebsite;
      if (!website) {
        return "";
      }
      return website.license_id;
    },
  },
};
