export default {
  title: "Users",
  createNew: "Create new user",
  filterPlaceholder: "Filter by name or email",
  name: "Name",
  email: "Email",
  role: "Role",
  confirmDeleteMessage: "Are you sure you want to delete this user?",
  createUser: "Create User",
  editUser: "Edit User",
  generatePassword: "Generate Password",
  changePassword: "Change Password",
  forceChangePassword: "Force change password at next login",
  password: "Password",
  confirmPassword: "Confirm password",
  saveSuccessMessage: "User saved.",
  userIncompatibleSingleLoginLink:
    "A unique connection link cannot be created for this user.<br>The user must have access to Brickroad for the link to function.",
  roles: {
    approver: "Approver",
    siteadmin: "Site admin",
    viewer: "Viewer",
    manager: "Manager",
  },
  loginLinkCopied: "The link was successfully copied!",
  loginLinkCopiedError:
    "The message was succThe message could not be sent, please try again.",
  welcomeEmailSent: "The message was successfully sent.",
  welcomeEmailSentError: "The message could not be sent, please try again.",
};
