import Vue from "vue";
import Router from "vue-router";
import config from "./config";
import adminRoutes from "./router/admin";
import AdminApp from "@/AdminApp";
import FrontApp from "@/FrontApp";
import permissions from "@/mixins/permissions";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: FrontApp,
      children: [
        {
          path: "",
          name: "front.home",
          component: () =>
            import(
              /* webpackChunkName: "public-homepage" */ "@/views/public/Home.vue"
            ),
        },
        {
          path: "/request",
          name: "front.request",
          component: () =>
            import(
              /* webpackChunkName: "public-request" */ "@/views/public/Request.vue"
            ),
        },
        {
          path: "/privacy",
          name: "front.privacy",
          component: () =>
            import(
              /* webpackChunkName: "public-privacy" */ "@/views/public/Privacy.vue"
            ),
        },
        {
          path: "/terms",
          name: "front.terms",
          component: () =>
            import(
              /* webpackChunkName: "public-terms" */ "@/views/public/Terms.vue"
            ),
        },
        {
          path: "/property",
          name: "front.property",
          component: () =>
            import(
              /* webpackChunkName: "property" */ "@/views/siteadmin/requests/request/PropertyEvaluationForm.vue"
            ),
        },
      ],
    },
    {
      path: "/admin",
      component: AdminApp,
      children: adminRoutes,
    },
    // PUBLIC ROUTES
    {
      path: "*",
      component: () =>
        import(/* webpackChunkName: "errors" */ "@/views/errors/NotFound.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  let userPermissions = [];
  const permission = permissions.data().permissionEnum;
  let redirect = false;

  if (localStorage.getItem("permissions")) {
    userPermissions = localStorage.getItem("permissions").split(",");

    switch (to.name) {
      case "dashboard":
        redirect = !userPermissions.includes(permission.DashboardView);
        break;
      case "questions":
      case "questions.create":
      case "questions.edit":
      case "questions.choices.create":
      case "questions.choices.edit":
        redirect = !userPermissions.includes(permission.QuestionManage);
        break;
      case "requests":
      case "requests.byStatus":
      case "requests.show":
        redirect =
          !userPermissions.includes(permission.SubmissionsView) &&
          !userPermissions.includes(permission.SubmissionsViewOwn);
        break;
      case "requests.add":
        redirect =
          !userPermissions.includes(permission.SubmissionsEdit) &&
          !userPermissions.includes(permission.SubmissionsEditOwn);
        break;
      case "email-contents":
      case "email-contents.edit":
        redirect = !userPermissions.includes(permission.EmailManage);
        break;
      case "profile-fields":
      case "profile-fields.edit":
        redirect = !userPermissions.includes(permission.ProfileFieldsManage);
        break;
      case "pages":
      case "pages.edit":
        redirect = !userPermissions.includes(permission.PagesManage);
        break;
      case "styles":
        redirect = !userPermissions.includes(permission.StylesManageOwn);
        break;
      case "styles.edit":
        redirect = !userPermissions.includes(permission.StylesManage);
        break;
      case "settings":
        redirect = !userPermissions.includes(permission.SettingsManage);
        break;
      case "websites":
      case "websites.create":
      case "websites.edit":
      case "websites.copy":
      case "websites.create-admin":
        redirect = !userPermissions.includes(permission.WebsiteManage);
        break;
      case "users":
      case "users.create":
      case "user.edit":
        redirect = !userPermissions.includes(permission.UsersManage);
        break;
      case "site-users":
      case "site-users.create":
      case "site-user.edit":
        redirect = !userPermissions.includes(permission.UsersManageOwn);
        break;
      case "budgets.index":
      case "budgets.setup":
      case "budgets.create":
      case "budgets.edit":
        redirect = !userPermissions.includes(permission.BudgetsManage);
        break;
      case "accounts":
        redirect = !userPermissions.includes(
          permission.SubmissionsAccountManage,
        );
        break;
      case "reports.actual-budget":
      case "reports.actual-budget.account":
        redirect = !userPermissions.includes(permission.BudgetsView);
        break;
      case "reports.comparative":
        redirect = !userPermissions.includes(permission.BudgetComparativeView);
        break;
      case "groups.index":
      case "groups.create":
      case "groups.edit":
      case "groups.relations.create":
      case "groups.relations.edit":
        redirect = !userPermissions.includes(permission.GroupsManage);
        break;
      case "properties.create":
      case "properties.edit":
        redirect = !userPermissions.includes(
          permission.PropertyEvaluationManage,
        );
        break;
    }

    if (redirect) {
      let redirectRouteName = getRedirectRouteName(userPermissions, from);
      if (redirectRouteName) {
        next({ name: redirectRouteName });
      }
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = localStorage.getItem(config.auth.tokenKeyName);

    if (!token) {
      // const query = to.fullPath !== '/' ? { redirect: to.fullPath } : null

      next({
        // query,
        path: config.auth.guestRedirectPath,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresGuest)) {
    const token = localStorage.getItem(config.auth.tokenKeyName);

    if (token) {
      next({
        path: config.auth.memberRedirectPath,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

function getRedirectRouteName(userPermissions, from) {
  const permission = permissions.data().permissionEnum;
  let name = null;
  if (!userPermissions.length && from.name !== "admin.login") {
    name = "admin.login";
  } else if (
    userPermissions.includes(permission.SubmissionsView) ||
    userPermissions.includes(permission.SubmissionsViewOwn)
  ) {
    name = "requests";
  } else if (userPermissions.includes(permission.DashboardView)) {
    name = "dashboard";
  } else if (userPermissions.includes(permission.WebsiteManage)) {
    name = "websites";
  }
  return name;
}

export default router;
